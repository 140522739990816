const singleCourseFaqs = [
  {
    question: "What is a simulator-based course?",
    answer:
      "Most people learn better by doing, but it's better to practice before you land a high-stakes opportunity. This course puts you in real-life scenarios to give you that chance!",
  },
  {
    question: "When does the course start and finish?",
    answer:
      "The course starts now and never ends! It is a completely self-paced online course - you decide when you start and when you finish.",
  },
  {
    question: "How long do I have access to the course?",
    answer:
      "Free courses are open to anyone, and as a paying subscriber, you would also have unlimited access to all premium content - across any and all devices you own.",
  },
  {
    question: "What if I am unhappy with the course?",
    answer:
      "We would never want you to be unhappy! If you are unsatisfied with your subscription, contact us in the first 30 days and we will give you a full refund.",
  },
  {
    question: "Can I get this reimbursed by my employer?",
    answer:
      "Most large organizations, and many smaller ones, will reimburse tuition for professional development, so it can't hurt to ask!",
  },
];

export default singleCourseFaqs;
