import { extractTrendingCourses } from "@/lib/filters/product-filters";

export default function TrendingCourses({ allSimulatorData }) {
  {
    /* Trending courses */
  }

  const trendingCourses = extractTrendingCourses(allSimulatorData);

  return (
    <section aria-labelledby="trending-heading" className="bg-white">
      <div className="my-12 sm:my-24 lg:mx-auto lg:max-w-7xl lg:px-8">
        <div className="flex items-center justify-between px-4 sm:px-6 lg:px-0">
          <h2
            id="trending-heading"
            className="text-2xl font-extrabold tracking-tight text-black"
          >
            Trending Courses
          </h2>
          <a
            href="/courses/"
            className="hidden text-sm font-semibold text-secondary hover:text-secondary sm:block"
          >
            See all courses<span aria-hidden="true"> &rarr;</span>
          </a>
        </div>

        <div className="relative mt-8">
          <div className="relative w-full overflow-x-auto">
            <ul
              role="list"
              className="mx-4 inline-flex space-x-8 sm:mx-6 lg:mx-0 lg:grid lg:grid-cols-4 lg:gap-x-8 lg:space-x-0"
            >
              {Object.keys(trendingCourses)
                .slice(0, 4)
                .map((course) => (
                  <a
                    key={trendingCourses[course].courseID}
                    href={`/sim/${trendingCourses[course].slug}/`}
                  >
                    <li className="inline-flex w-64 flex-col text-center lg:w-auto">
                      <div className="group relative">
                        <div className="aspect-w-1 aspect-h-1 h-48 w-full overflow-hidden rounded-md bg-gray-mid">
                          <img
                            src={trendingCourses[course].image}
                            alt={`Header image for the course ${trendingCourses[course].title}`}
                            className="h-full w-full object-cover object-center group-hover:opacity-75"
                          />
                        </div>
                        <div className="mt-6">
                          <p className="text-sm text-gray-dark">
                            {trendingCourses[course].topic}
                          </p>
                          <h3 className="mt-1 font-semibold text-black">
                            <span className="absolute inset-0" />
                            {trendingCourses[course].title}
                          </h3>
                        </div>
                      </div>
                    </li>
                  </a>
                ))}
            </ul>
          </div>
        </div>

        <div className="mt-12 px-4 sm:hidden">
          <a
            href="/courses/"
            className="text-sm font-semibold text-secondary hover:text-secondary"
          >
            See all courses<span aria-hidden="true"> &rarr;</span>
          </a>
        </div>
      </div>
    </section>
  );
}
