/* This example requires Tailwind CSS v2.0+ */
export default function PaidCoursesVsFreeCoursesSection() {
  return (
    <section className="mt-12 sm:mt-24 xl:mx-auto xl:max-w-7xl xl:px-8">
      <div className="bg-gray-light">
        <div className="grid min-h-screen grid-cols-1 grid-rows-2 md:h-auto md:min-h-0 lg:grid-cols-2 lg:grid-rows-1">
          <div className="relative flex">
            <img
              src="https://tailwindui.com/img/ecommerce-images/home-page-02-edition-01.jpg"
              alt=""
              className="absolute inset-0 h-full w-full object-cover object-center"
            />
            <div className="relative flex w-full flex-col items-start justify-end bg-black bg-opacity-40 p-8 sm:p-12">
              <h2 className="text-lg font-medium text-white text-opacity-75">
                Free Courses
              </h2>
              <p className="mt-1 text-2xl font-medium text-white">
                Try without signing up
              </p>
              <a
                href="/courses/"
                className="mt-4 w-52 rounded-md bg-white py-2.5 px-4 text-center text-sm font-medium text-black hover:bg-gray-light"
              >
                See Free Courses
              </a>
            </div>
          </div>
          <div className="relative flex">
            <img
              src="https://tailwindui.com/img/ecommerce-images/home-page-02-edition-02.jpg"
              alt=""
              className="absolute inset-0 h-full w-full object-cover object-center"
            />
            <div className="relative flex w-full flex-col items-start justify-end bg-black bg-opacity-40 p-8 sm:p-12">
              <h2 className="text-lg font-medium text-white text-opacity-75">
                Premium Courses
              </h2>
              <p className="mt-1 text-2xl font-medium text-white">
                Open to paying members
              </p>
              <a
                href="/courses/#paid"
                className="mt-4 w-52 rounded-md bg-white py-2.5 px-4 text-center text-sm font-medium text-black hover:bg-gray-light"
              >
                See Premium Courses
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
