import { classNames } from "@/lib/helpers";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Disclosure } from "@headlessui/react";
import { FAQPageJsonLd } from "next-seo";
import singleCourseFaqs from "@/data/faq";

export default function DefaultFAQSection({
  faqs,
}: {
  faqs?: { question: string; answer: string }[];
}) {
  // Check if there are any FAQs passed in, if not, use the default FAQs
  if (!faqs) {
    faqs = singleCourseFaqs;
  }

  return (
    <section className="bg-gray-light">
      <div className="mx-auto max-w-7xl py-12 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-3xl divide-y-2 divide-gray-200">
          <h2 className="text-center text-3xl font-extrabold text-black sm:text-4xl">
            Frequently Asked Questions
          </h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {/* FAQ Schema */}
            <FAQPageJsonLd
              mainEntity={singleCourseFaqs.map((faq) => ({
                questionName: faq.question,
                acceptedAnswerText: faq.answer,
              }))}
            />

            {/* FAQ Questions + Answers */}
            {faqs.map((faq, index) => (
              <Disclosure
                as="div"
                key={`faq.question_${index}`}
                className="pt-6"
              >
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="focus:outline-none flex w-full items-start justify-between text-left text-gray-dark">
                        <span className="font-medium text-black">
                          {faq.question}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? "-rotate-180" : "rotate-0",
                              "h-6 w-6 transform"
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-gray-dark">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </section>
  );
}
