import { capitalizeFirstLetter } from "@/lib/helpers";

// 1. Extract Free vs Paid Courses:
function filterProductCourseFreeVsPaid(allSimulatorData, expression) {
  if (!expression || (expression !== "Free" && expression !== "Premium")) {
    return <></>;
  }

  let newObj = {};
  for (let [key, value] of Object.entries(allSimulatorData)) {
    if (value.access === expression) {
      newObj[key] = value;
    }
  }
  return newObj;
}

// 2. Extract all of the unique topics from the courses:
function extractTopicsFromAllCourses(allSimulatorData) {
  let newObj = {};
  for (let value of Object.values(allSimulatorData)) {
    newObj[value.topic] = {
      id: value.topic,
      name: value.topic,
      topicInfo: value.topicInfo,
      topicLink: value.topicLink,
      image: value.image,
    };
  }
  return newObj;
}

// 3. Extract all of the unique categories from the courses:
function extractCategoriesFromAllCourses(allSimulatorData) {
  let newObj = {};
  for (let value of Object.values(allSimulatorData)) {
    newObj[value.category.name] = {
      id: value.category.name,
      name: capitalizeFirstLetter(value.category.name),
      path: value.category.name,
      image: value.category.image,
    };
  }
  return newObj;
}

// 4. Extract Recent courses:
function extractMostRecentCourses(allSimulatorData) {
  let sortedObj = {};
  Object.keys(allSimulatorData)
    .sort(function (a, b) {
      return allSimulatorData[a].publishedAt - allSimulatorData[b].publishedAt;
    })
    .forEach(function (key) {
      sortedObj[key] = allSimulatorData[key];
    });
  return sortedObj;
}

// 5. Extract the top 5 newest courses: (refactor later to be based on user behaviour):
function extractTrendingCourses(allSimulatorData) {
  let sortedObj = {};
  Object.keys(allSimulatorData)
    .sort(function (a, b) {
      return allSimulatorData[b].publishedAt - allSimulatorData[a].publishedAt;
    })
    .forEach(function (key) {
      sortedObj[key] = allSimulatorData[key];
    });
  return sortedObj;
}

function filterCoursesByCategory(allSimulatorData, category) {
  let newObj = {};
  for (let value of Object.values(allSimulatorData)) {
    if (value.category.name == category) {
      newObj[value.courseID] = value;
    }
  }
  return newObj;
}

export {
  filterProductCourseFreeVsPaid,
  extractTopicsFromAllCourses,
  extractCategoriesFromAllCourses,
  extractMostRecentCourses,
  extractTrendingCourses,
  filterCoursesByCategory,
};
