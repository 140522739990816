import {
  AcademicCapIcon,
  TemplateIcon,
  ShieldCheckIcon,
} from "@heroicons/react/outline";
export default function USPSection() {
  const incentives = [
    {
      name: "Realistic Training Scenarios",
      icon: AcademicCapIcon,
      description:
        "Practice real-world scenarios based on the experience of vetted marketing experts.",
    },

    {
      name: "Useful Exercise Templates",
      icon: TemplateIcon,
      description:
        "Learn how the pros actually do the job, and download their templates for you to use.",
    },
    {
      name: "Money-Back Guarantee",
      icon: ShieldCheckIcon,
      description:
        "If you aren't happy with your subscription, contact us within 30 days for a full refund.",
    },
  ];

  return (
    <div className="sm:my24 my-12 bg-white">
      <div className="mx-auto max-w-7xl px-4 py-8 sm:py-0 sm:px-6 lg:px-8">
        <div className="rounded-2xl bg-gray-light px-6 py-16 sm:p-16">
          <div className="mx-auto max-w-xl lg:max-w-none">
            <div className="text-center">
              <h2 className="text-2xl font-extrabold tracking-tight text-black">
                Learn faster with our simulator-based courses
              </h2>
            </div>
            <div className="mx-auto mt-12 grid max-w-sm grid-cols-1 gap-y-10 gap-x-8 sm:max-w-none lg:grid-cols-3">
              {incentives.map((incentive) => (
                <div
                  key={incentive.name}
                  className="text-center sm:flex sm:text-left lg:block lg:text-center"
                >
                  <div className="sm:flex-shrink-0">
                    <div className="flow-root">
                      <incentive.icon
                        className="mx-auto h-10 w-10 text-background-dark"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                    <h3 className="text-sm font-medium text-black">
                      {incentive.name}
                    </h3>
                    <p className="mt-2 text-sm text-gray-dark">
                      {incentive.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
