import Footer from "@/components/Footer";
import WebsiteNavigation from "./navigation/WebsiteNavigation";

export default function PageLayout({ children, navigation }) {
  return (
    <>
      <WebsiteNavigation navigation={navigation} />
      {/* Main Content */}
      <main className="focus:outline-none relative z-0 flex-1 overflow-hidden overflow-y-auto bg-gray-100">
        {children}
      </main>
      <Footer />
    </>
  );
}
