import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";

// Sections:
import CategoryGridSection from "@/components/homepage/CategoryGridSection";
import DefaultFAQSection from "@/components/FAQSection";
import navigation from "@/data/navigation-links";
import PaidCoursesVsFreeCoursesSection from "@/components/homepage/PaidCoursesVsFreeCoursesSection";
import TrendingCourses from "@/components/homepage/TrendingCourses";
import USPSection from "@/components/homepage/USPSection";

// Custom imports:
import PageLayout from "@/components/PageLayout";
import { getAllSimulatorData } from "@/lib/simulator";
import { useEffect } from "react";

export default function HomePage({ allSimulatorData }) {
  const logoScaleClass =
    "object-scale-down filter grayscale contrast-25 opacity-50 invert";
  const logoWidth = "150";
  const logoHeight = "75";
  const router = useRouter();
  useEffect(() => {
    router.push("/courses/");
  }, []);

  return (
    <>
      <PageLayout navigation={navigation}>
        {/* Hero Section */}
        <div className="bg-gray-light">
          <div className="relative overflow-hidden">
            <div
              className="absolute inset-y-0 h-full w-full"
              aria-hidden="true"
            >
              <div className="relative h-full">
                <svg
                  className="absolute right-full translate-y-1/3 translate-x-1/4 transform sm:translate-x-1/2 md:translate-y-1/2 lg:translate-x-full"
                  width={404}
                  height={784}
                  fill="none"
                  viewBox="0 0 404 784"
                >
                  <defs>
                    <pattern
                      id="e229dbec-10e9-49ee-8ec3-0286ca089edf"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-light"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={784}
                    fill="url(#e229dbec-10e9-49ee-8ec3-0286ca089edf)"
                  />
                </svg>
                <svg
                  className="absolute left-full -translate-y-3/4 -translate-x-1/4 transform sm:-translate-x-1/2 md:-translate-y-1/2 lg:-translate-x-3/4"
                  width={404}
                  height={784}
                  fill="none"
                  viewBox="0 0 404 784"
                >
                  <defs>
                    <pattern
                      id="d2a68204-c383-44b1-b99f-42ccff4e5365"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-light"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={784}
                    fill="url(#d2a68204-c383-44b1-b99f-42ccff4e5365)"
                  />
                </svg>
              </div>
            </div>

            <div className="relative pt-6 pb-12 sm:pb-24">
              <div className="mx-auto mt-16 max-w-7xl px-4 sm:mt-24 sm:px-6">
                <div className="text-center">
                  <h1 className="text-4xl font-extrabold tracking-tight text-black sm:text-5xl md:text-6xl">
                    <span className="block">Learn faster inside of a</span>
                    <span className="block text-secondary">simulation.</span>
                  </h1>
                  <p className="mx-auto mt-3 max-w-md text-base text-gray-dark sm:text-lg md:mt-5 md:max-w-3xl md:text-xl">
                    No more boring lecture videos – learn to be more data-driven
                    with realistic training scenarios from experienced
                    marketers.
                  </p>
                  <Link
                    href={{
                      pathname: "/courses/",
                    }}
                  >
                    <button className="text-md focus:outline-none mt-8 inline-flex w-48 items-center justify-center border border-transparent bg-primary py-3 font-medium text-white shadow hover:bg-secondary focus:bg-secondary focus:ring-2 focus:ring-secondary focus:ring-offset-2 sm:px-6 md:shadow-md">
                      {" "}
                      View Courses{" "}
                    </button>
                  </Link>
                </div>
              </div>
            </div>

            <div className="relative">
              <div
                className="absolute inset-0 flex flex-col"
                aria-hidden="true"
              >
                <div className="flex-1" />
                <div className="w-full flex-1 bg-background-dark" />
              </div>
              <div className="mx-auto max-w-7xl px-4 sm:px-6">
                <img
                  className="relative rounded-lg border-8 border-opacity-60 shadow-2xl"
                  src="https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/courses%2FScreenshot%202022-01-25%20204833.png?alt=media&token=79148111-07e9-4c88-95fd-b67533e4c14c"
                />
              </div>
            </div>
          </div>
          <div className="bg-background-dark">
            <div className="mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
              <h2 className="text-center text-sm font-semibold uppercase tracking-wide text-gray-mid">
                Based on Experience
              </h2>
              <p className="mx-auto mt-4 w-2/3 text-center text-gray-mid">
                Vetted industry professionals, that have experience working with
                these companies and others, consulted in an advisory capacity to
                ensure the content of our courses is realistic.
              </p>
              <div className="mt-8 grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
                <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                  <Image
                    width={logoWidth}
                    height={logoHeight}
                    src="https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/images%2FBooking.Com-logo.png?alt=media&token=28e5d427-f02d-4e98-b8fa-9aae95754fab"
                    alt="Booking.com"
                    className={logoScaleClass}
                  />
                </div>
                <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                  <Image
                    width={logoWidth}
                    height={logoHeight}
                    src="https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/images%2FMonzo_logo.svg.png?alt=media&token=a65a5dba-6826-4b8b-b12c-80ea88ce342a"
                    alt="Monzo Bank"
                    className={logoScaleClass}
                  />
                </div>
                <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                  <Image
                    width={logoWidth}
                    height={logoHeight}
                    src="https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/images%2FTime-Out-logo.png?alt=media&token=29d0b5be-cbde-484d-aca7-5010db1c3d29"
                    alt="Time Out"
                    className={logoScaleClass}
                  />
                </div>
                <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
                  <Image
                    width={logoWidth}
                    height={logoHeight}
                    src="https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/images%2Fimages-removebg-preview.png?alt=media&token=9b09a235-a06a-4824-86ce-4eb3f80a4dce"
                    alt="Criteo"
                    className={logoScaleClass}
                  />
                </div>
                <div className="col-span-2 flex justify-center md:col-span-3 lg:col-span-1">
                  <Image
                    width={logoWidth}
                    height={logoHeight}
                    src="https://firebasestorage.googleapis.com/v0/b/vexpower-2b2c5.appspot.com/o/images%2Ftravelex-logo.png?alt=media&token=c1d5e3d6-cfe4-4a57-b779-1eab3880cc46"
                    alt="Travelex"
                    className={logoScaleClass}
                  />
                </div>
              </div>
              <div className="mx-auto mt-8 w-2/3">
                <p className="mx-auto text-center text-sm text-gray-mid">
                  <small>
                    Our courses are works of fiction. Unless otherwise
                    indicated, all the names, characters, businesses, places,
                    events and incidents in our courses are either the product
                    of the author's imagination or used in a fictitious manner.
                    Any resemblance to actual persons, living or dead, or actual
                    events is purely coincidental.
                  </small>
                </p>
              </div>
            </div>
          </div>
        </div>
        <TrendingCourses allSimulatorData={allSimulatorData} />
        <USPSection />
        <CategoryGridSection allSimulatorData={allSimulatorData} />
        <PaidCoursesVsFreeCoursesSection />

        <div className="mt-32">
          <DefaultFAQSection />
        </div>
      </PageLayout>
    </>
  );
}

export async function getStaticProps() {
  const allSimulatorData = await getAllSimulatorData();

  return {
    props: {
      allSimulatorData,
    },
  };
}
