import { extractCategoriesFromAllCourses } from "@/lib/filters/product-filters";

export default function CategoryGridSection({ allSimulatorData }) {
  const categories = extractCategoriesFromAllCourses(allSimulatorData);

  {
    /* Categories */
  }

  return (
    <section
      aria-labelledby="category-heading"
      className="mt-12 sm:mt-24 xl:mx-auto xl:max-w-7xl xl:px-8"
    >
      <div className="px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 xl:px-0">
        <h2
          id="category-heading"
          className="text-2xl font-extrabold tracking-tight text-black"
        >
          View by Category
        </h2>
        <a
          href="/courses/"
          className="hidden text-sm font-semibold text-secondary hover:text-secondary sm:block"
        >
          Browse all Categories<span aria-hidden="true"> &rarr;</span>
        </a>
      </div>

      <div className="mt-4 flow-root">
        <div className="-my-2">
          <div className="relative box-content h-80 overflow-x-auto py-2 xl:h-auto xl:overflow-visible">
            <div className="min-w-screen-xl absolute flex space-x-8 px-4 sm:px-6 lg:px-8 xl:relative xl:grid xl:grid-cols-5 xl:gap-x-8 xl:space-x-0 xl:px-0">
              {Object.keys(categories).map((category) => (
                <a
                  key={categories[category].name}
                  href={`/category/${categories[category].path}/`}
                  className="relative flex h-80 w-56 flex-col overflow-hidden rounded-lg p-6 hover:opacity-75 xl:w-auto"
                >
                  <span aria-hidden="true" className="absolute inset-0">
                    <img
                      src={categories[category].image}
                      alt=""
                      className="h-full w-full object-cover object-center"
                    />
                  </span>
                  <span
                    aria-hidden="true"
                    className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-background-dark opacity-50"
                  />
                  <span className="relative mt-auto text-center text-xl font-bold text-white">
                    {categories[category].name}
                  </span>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 px-4 sm:hidden">
        <a
          href="/courses/"
          className="block text-sm font-semibold text-secondary hover:text-secondary"
        >
          Browse all Categories<span aria-hidden="true"> &rarr;</span>
        </a>
      </div>
    </section>
  );
}
